// support function to ensure we check existing values on the hint
const checkOriginalException = fn => (_data, hint) =>
  fn({
    message: (hint && hint.originalException && hint.originalException.message) || '',
    stack: (hint && hint.originalException && hint.originalException.stack) || '',
    name: (hint && hint.originalException && hint.originalException.name) || '',
    exception: hint?.originalException ?? null,
  });

// This error is linked to Facebook bot IPs
// It happens on charging the geist tracker at //i.geistm.com/x/Prose
//
// Started filtering it out on 2018-10-25

export const isFacebookDataclone = checkOriginalException(
  ({ message, name }) =>
    name === 'DataCloneError' && /^Failed to execute 'postMessage' on 'Window'/.test(message)
);

// This error is linked to trackers code blocked by some browsers
//
// Started filtering it out on 2018-10-25

export const isTrackerSecurityError = checkOriginalException(
  ({ name, message }) =>
    name === 'SecurityError' &&
    /^(Blocked a frame with origin "https:\/\/prose.com").+(Protocols, domains, and ports must match.)$/.test(
      message
    )
);

// This error is linked to misfunction of google tag manager on some browsers
//
// Started filtering it out on 2018-11-26
export const isTagManagerError = checkOriginalException(({ message }) =>
  /Can't find variable: google_tag_manager/i.test(message)
);

// This error is linked to local storage issue on Safari 10
//
// Started filtering it out on 2019-03-26
export const isQuotaExceededError = checkOriginalException(
  ({ name }) => name === 'QuotaExceededError'
);

// This error is linked to Greenhouse snippet
//
// Started filtering it out on 2019-04-04
export const isGreenhouseError = checkOriginalException(
  ({ message, stack }) =>
    [
      "null is not an object (evaluating 'instance.htmlElement.contentWindow.postMessage')",
      "Cannot read property 'postMessage' of null",
    ].includes(message) &&
    stack.indexOf('https://boards.greenhouse.io/embed/job_board/js?for=prose') >= 0
);

// This error is linked to sendBeacon function used in some of external tools/gtm scripts
//
// Started filtering it out on 2022-02
export const isSendBeaconError = checkOriginalException(({ stack }) => {
  return stack.includes('sendBeacon');
});

// This error comes from instagram / facebook browser and has appeared for a lot of people with no
// reasons at the exact same day see https://stackoverflow.com/questions/72488297/referenceerror-getvaluesofautofillinputs-cant-find-variable-paymentautofillco
//
// Started filtering it out on 2022-06-07
const isInstaFbMissingPaymentAutofillConfig = checkOriginalException(
  ({ message }) => message === "Can't find variable: PaymentAutofillConfig"
);

// https://sentry.io/organizations/prose/issues/2875367365/
const isGiftWithPurchaseMiddlewareError = checkOriginalException(
  ({ message }) =>
    message === '[GiftWithPurchase] fetchGiftWithPurchaseList: Error: failed_to_fetch'
);

const isReduxActionMustBePlainObjectError = checkOriginalException(({ message }) =>
  message.includes('Minified Redux error #7')
);

/**
 * INFO: 401 & 403 are expected return codes when a request don't have the necessary right required by the server
 */
const shouldIgnoreStatusCode = checkOriginalException(({ exception }) =>
  [401, 403].includes(exception?.response?.status)
);

export const shouldRepportError = (...params) => {
  try {
    return ![
      isFacebookDataclone,
      isTrackerSecurityError,
      isTagManagerError,
      isQuotaExceededError,
      isGreenhouseError,
      isSendBeaconError,
      isInstaFbMissingPaymentAutofillConfig,
      isGiftWithPurchaseMiddlewareError,
      isReduxActionMustBePlainObjectError,
      shouldIgnoreStatusCode,
    ].some(f => f(...params));
  } catch (_error) {
    return true;
  }
};

export const ignoreErrors = [
  'ChunkLoadError',
  'TypeError: Failed to fetch',
  'SecurityError',
  'Cannot fetch aggressors from hair profile',
  'TypeError: e.matches is not a function',
  "TypeError: Cannot read properties of null (reading 'key')",
  'Non-Error promise rejection captured with keys: stack',
];
